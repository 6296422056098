import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../styles/legalisation.module.css'

export default function Legalisation() {
    return (
        <Layout>
            <section className={styles.legalisation}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        What is a legalisation?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            A legalised document or a document which has a Apostille Certificate is one and the same. Our clients often call us confused thinking they need to have their documents apostilled AND legalised. Therefore, you only need to pay once.
                        </p>
                        <p className="paragraph">
                            If you live in the UK and you use your UK issued document in the UK you do not need to have it legalised.
                        </p>
                        <p className="paragraph">
                            There are cases where you would need to have your documents notarised before they are legalised.
                        </p>
                        <p className="paragraph">
                            <a href="mailto:info@apostille-express.co.uk">Contact us for more details.</a>
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <div className={styles.image_container}>
                            <StaticImage
                            src="../images/notary-public.png"
                            alt="Notary Public"
                            placeholder="blurred"
                            layout="fullWidth"
                            />
                        </div>
                    </div>
                </div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        When do I need to have my documents legalised?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            We have created a little test for you. If you can tick all the boxes below, then you will need an Apostille.
                        </p>
                        <p className="paragraph">
                            As a general rule of thumb you will need an an Apostille on your documents  if ALL the boxes below can be ticked.
                        </p>
                        <p className="paragraph">
                            <ul>
                                <li>
                                    the country where the document was issued has signed the Hague Convention; <span className="text-bold">and</span>
                                </li>
                                <li>
                                    the country in which the document is to be used has signed the Hague Convention; <span className="text-bold">and</span>
                                </li>
                                <li>
                                    the legislation of the country where the document was issued considers it to be a public document; <span className="text-bold">and</span>
                                </li>
                                <li>
                                    the country in which the document is to be used requires an Apostille Certificate in order to recognise it as a valid document.
                                </li>
                            </ul>
                        </p>
                        <p className="paragraph">
                            Therefore if you wish to check yourself which country accepts an Apostille Certificate, please make sure you check if both the country where the public document was issued and the country where the document is to be used are listed.
                        </p>
                        <p className="paragraph">
                            To simplify your search read the formula below:
                        </p>
                        <p className="paragraph">
                            Apostille from a country on the list + presented in a country not on list = not compatible and NOT accepted.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <h3 className="heading-tertiary u-margin-bottom-medium">
                            IMPORTANT INFORMATION
                        </h3>
                        <p className="paragraph">
                            If you use a search engine to look for Hague Agreement/Convention countries and you do not wish to use our list of countries, 
                            kindly note that there is a great difference between names of <Link to="/countries">countries</Link> who have signed the Apostille Convention and the other lists of countries on the Hague Conference website, such as the list of Members of the Hague Conference on Private International Law or list of countries of other Hague Conventions.
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-medium">
                            What do I do if either the country where my public document was issued or the country where I need to use my public document is not a party to the Apostille Convention?
                        </h3>
                        <p className="paragraph">
                            Contact the embassy of that country and they will be able to advise you further. They may require to have these documents translated. We work with all embassies for translation purposes.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
